@import '../../../styles/variables';
@import '~@andes/tooltip/index';
@import '~@andes/radio-button/index';
@import '~@andes/progress-indicator-circular/index';
@import '~@andes/form/index';
@import '~@andes/textfield/index';

.tooltip-periodicity h3 {
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
}

.tooltip-periodicity label {
  font-size: $font-size-16;
}

.#{$search-prefix}-alert__save-modal {
  width: 800px;
  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-semibold;
    line-height: 35px;
    color: $andes-black;
    margin-left: -$andes-spacing-32;
    margin-bottom: $andes-spacing-8;
  }

  .#{$search-prefix}-alert__input-label {
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: $font-weight-semibold;
    color: $andes-black;
    margin-bottom: $andes-spacing-16;
  }

  .andes-modal__header .andes-modal__title {
    padding-top: 0;
  }

  .andes-form-control__label {
    margin: 0;
  }

  .andes-modal__close-button {
    stroke: none;
    filter: none;
  }
}

.#{$search-prefix}-alert__modal-subtitle {
  color: $search-gray-550;
  font-size: $font-size-14;
  font-weight: $font-weight-normal;
  line-height: $line-height-18;
  margin-left: -$andes-spacing-32;
}

.#{$search-prefix}-alert__submit-container {
  margin-top: 30px;
}

.#{$search-prefix}-alert__modal-content {
  padding-top: 2px;

  hr {
    margin: 30px 0;
    height: 1px;
    background-color: $seach-background-spotlight-off;
    border: none;
  }

  p {
    color: $search-gray-r090;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    line-height: $line-height-20;
  }
}

.#{$search-prefix}-alert__notifications-switch {
  margin: $andes-spacing-28 0;
  padding: 0;
}

.#{$search-prefix}-alert__frequency-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .#{$search-prefix}-alert__frequency-dropdown,
  .#{$search-prefix}-alert__expiration-dropdown {
    width: 47%;
  }
}

.#{$search-prefix}-alert__card {
  display: flex;
  position: relative;
  align-items: center;
}

.#{$search-prefix}-alert__card--edit-alert {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 14px;
  width: max-content;
}

.#{$search-prefix}-alert__btn-alert {
  background-color: transparent;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  outline: none;
  align-items: center;
  font-size: $font-size-14;

  p {
    color: $search-dodger-blue;
    cursor: pointer;
    margin-left: 2px;
  }
}

.#{$search-prefix}-alert__btn-save-new-search {
  background-color: transparent;
  border: none;
  display: flex;
  margin: 0 0 $andes-spacing-20 0;
  padding: 0;
  outline: none;
  align-items: center;
  font-size: $font-size-14;
  box-shadow: none !important;

  p {
    color: $search-dodger-blue;
    cursor: pointer;
    margin-left: $andes-spacing-8;
    margin-bottom: 2px;
  }
}

.#{$search-prefix}-alert__card--alert {
  width: max-content;
  margin-bottom: 14px;
  margin-top: -7px;
}

.#{$search-prefix}-alert__not_logged {
  margin-bottom: 14px;
  margin-top: -7px;
}

.#{$search-prefix}-alert__card--alert::before,
.#{$search-prefix}-alert__card--edit-alert::before {
  background-color: transparent;
  content: none;
  height: 0;
  margin: 0;
  width: 0;
}

.#{$search-prefix}-alert__card::before {
  background-color: $search-gray-700;
  content: '';
  height: 16px;
  margin: 0 10px 0 12px;
  width: 1px;
}

.#{$search-prefix}-alert__btn {
  background-color: transparent;
  border: none;
  display: flex;
  margin-right: 10px;
  outline: none;
}

.#{$search-prefix}-alert__alert-message {
  width: 260px;
  z-index: $z-index-xl;
  font-size: $font-size-12;
  padding: 10px 0 10px 16px;

  .andes-message__badge--neutral {
    display: none;
  }
}

.#{$search-prefix}-alert__icon-alert {
  width: 23px;
  cursor: pointer;
}

.#{$search-prefix}-alert__icon-search {
  cursor: pointer;
}

.#{$search-prefix}-alert__icon-alert-edit {
  width: 23px;
}

.#{$search-prefix}-alert__content {
  margin-left: 7px;
}

.#{$search-prefix}-alert-edition-tooltip {
  width: 250px;
  padding: 9px;
  border-radius: 2px;

  p {
    margin-bottom: 5px;
  }
}

.#{$search-prefix}-alert-edition-tooltip__content {
  display: flex;
  font-size: $font-size-12;
  line-height: $font-size-12;
}

.#{$search-prefix}-alert__alert-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: -7px;
  font-size: $font-size-14;
}

.#{$search-prefix}-alert__alert-edit-text {
  margin-left: 3px;
  font-size: $font-size-14;
  color: $search-dodger-blue;
  margin-top: 2px;
}

.#{$search-prefix}-alert-edition-tooltip__content-alert {
  display: flex;
  flex-direction: column;
  font-size: $font-size-14;
  line-height: $font-size-18;
  padding: 2px 0;
}

.#{$search-prefix}-alert-edition-tooltip__text {
  color: $search-gray-1000;
  font-weight: $font-weight-light;
}

.#{$search-prefix}-alert-edition-tooltip__text-alert {
  color: $search-gray-1000;
  font-weight: $font-weight-normal;
  margin-bottom: 8px;
}

.#{$search-prefix}-alert-edition-tooltip__action-link-alert {
  margin: 0 3px;
  font-weight: $font-weight-semibold;
}

.#{$search-prefix}-alert-edition-tooltip__action-link {
  margin: 0 3px;
  font-weight: 100;
}

.andes-tooltip:not(.ui-search-tooltip-filter) {
  .andes-tooltip-content {
    .andes-tooltip-arrow::before {
      top: -5px;
      width: 0.5em;
      height: 0.5em;
    }
  }
}

.#{$search-prefix}-alert__save-search-tooltip-info {
  width: 365px !important;
  padding-right: 10px !important;
  z-index: 1 !important;
}

.#{$search-prefix}-alert__input-wrapper {
  display: flex;

  .andes-form-control--textfield {
    flex-basis: 50%;
  }
}

.andes-modal__portal .andes-modal__scroll {
  overflow: unset;
}

.#{$search-prefix}-alert__label-new div#tippy-6 {
  z-index: 1 !important;
}
