.#{$search-prefix}-segment-top-keywords {
  margin-block-end: $andes-spacing-16;

  &__header {
    font-size: $font-size-18;
    font-weight: $font-weight-semibold;
    margin-block-end: $andes-spacing-8;
  }

  &__quick-access {
    display: flex;
    flex-wrap: wrap;
    gap: $andes-spacing-8;

    .andes-button--medium {
      border-radius: 56px;
    }
  }
}