@import '../../../../styles/variables';

$search-billboard-content-background-color: rgb(0 0 0 / 50%);
$search-billboard-height: 360px;
$search-billboard-collapsed-height: 104px;
$andes-slide: 'andes-carousel-snapped__slide';
$andes-carousel: 'andes-carousel-snapped';
$arrow-spaces: 15px;
$arrow-size: 50px;

.#{$search-prefix}-carousel--billboard {
  width: 100%;
  max-width: 885px;
  background-color: $andes-white;
  border-radius: $border-radius-4;
  height: $search-billboard-height;
  margin-bottom: $andes-spacing-16;
  margin-top: $search-spacing-8;
  padding: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  position: relative;
  font-weight: $font-weight-regular;

  .#{$andes-carousel}__control {
    background-color: transparent;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &--next {
      right: $arrow-spaces;
    }

    &--previous {
      left: $arrow-spaces;
    }

    svg {
      width: $arrow-size;
      height: $arrow-size;
      filter: drop-shadow(0 0 1px #000);

      path {
        fill: $andes-white;
      }
    }
  }

  .#{$search-prefix}-carousel {
    &__btn-collapse {
      width: 44px;
      height: 44px;
      display: block;
      border-width: 0;
      background: transparent;
      cursor: pointer;
      outline: 0;
      position: absolute;
      z-index: 2;
      transform: rotate(90deg);
      bottom: $andes-spacing-12;
      right: $andes-spacing-12;
      transition: transform 0.2s ease-in-out;

      &:focus-visible {
        border: 2px solid $search-blue-600;
        border-radius: 6px;
      }
    }

    &__btn-collapsed {
      bottom: 0;
      transform: rotate(-90deg);
    }

    &__btn-collapse::before,
    &__btn-collapse::after {
      content: '';
      display: block;
      width: 20px;
      height: 3px;
      background: $andes-white;
      position: absolute;
      left: 50%;
      box-shadow: 0 1px 1px 0 $search-gray-620;
    }

    &__btn-collapse::before {
      transform: translate(-50%) rotate(-45deg);
      top: 50%;
      margin-top: -$andes-spacing-8;
    }

    &__btn-collapse::after {
      transform: translate(-50%) rotate(45deg);
      bottom: 50%;
      margin-bottom: -$andes-spacing-8;
    }
  }

  .#{$search-prefix}-bookmark {
    background: rgba($andes-white, 0.7);
    border-radius: 50%;
    opacity: 0;
    overflow: hidden;
    right: $andes-spacing-16;
    top: $andes-spacing-16;
    transition: opacity 200ms;

    &--hover-disabled {
      opacity: 1;
    }
  }

  .#{$search-prefix}-bookmark,
  .#{$search-prefix}-bookmark__btn {
    @include square(36px);

    pointer-events: none;
  }

  .#{$search-prefix}-bookmark__btn:hover {
    .#{$search-prefix}-bookmark__icon-bookmark {
      stroke: $search-blue-600;
    }
  }

  .#{$search-prefix}-bookmark__icon-bookmark {
    @include square(18px);

    top: 50%;
    left: 25%;
    position: absolute;
    transform: translateY(-50%);

    &-fill {
      top: 29%;
      left: 22%;
    }
  }

  &:hover {
    .#{$search-prefix}-bookmark,
    .#{$search-prefix}-bookmark__btn {
      pointer-events: all;
      opacity: 1;
      display: block;
      cursor: pointer;
    }
  }

  .#{$search-prefix}-billboard {
    &__card.#{$andes-slide} {
      color: $andes-white;
      display: block;
      height: $search-billboard-height;
      position: relative;
    }

    &__content {
      background-color: $search-billboard-content-background-color;
      border-radius: $border-radius-4;
      padding: $andes-spacing-24;
      position: absolute;
      transition: 0.4s ease-in-out;
      top: 37px;
      left: 54px;
      max-height: 286px;
      width: 324px;

      .#{$search-prefix}-price__second-line {
        display: flex;
      }
    }

    &__link,
    &__link:hover,
    &__link:visited {
      color: $andes-white;
    }

    &__header {
      display: flex;
      margin-bottom: $andes-spacing-20;
    }

    &__logo-image-container {
      @include square(78px);

      align-items: center;
      background-color: $andes-white;
      border-radius: $border-radius-4;
      margin: 0 $andes-spacing-12 0 0;
      overflow: hidden;
      padding: $andes-spacing-4;
      display: flex;
    }

    &__logo-image {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      position: relative;
      width: auto;
      object-fit: contain;
      object-position: center;
    }

    &__strapline,
    &__from {
      display: block;
      font-size: $font-size-12;
      line-height: 10px;
      margin-bottom: 6px;
    }

    &__from {
      margin-top: -$andes-spacing-8;
    }

    &__title {
      font-size: $font-size-18;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 45px;
      text-decoration: none;
    }

    &__price {
      font-size: 26px;
      margin-bottom: $andes-spacing-12;
    }

    &-attributes {
      display: flex;
      justify-content: flex-start;
      margin-bottom: $andes-spacing-16;

      &--three-cols {
        justify-content: space-between;
      }
    }

    &-attributes__attribute {
      line-height: 1;
      margin-right: $andes-spacing-12;
      min-width: 84px;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }

      &--auto-width {
        min-width: auto;
      }
    }

    &-attributes__label,
    &-attributes__data {
      display: block;
    }

    &-attributes__label {
      font-size: $font-size-12;
      margin-bottom: $andes-spacing-12;
    }

    &-attributes__data {
      font-size: $font-size-14;
      margin-top: auto;
    }

    &__action-button {
      padding: 10px $andes-spacing-24;
      font-weight: $font-weight-regular;
    }
  }

  @media (width <= 720px) {
    width: 85%;
  }
}

.#{$search-prefix}-carousel--billboard.#{$search-prefix}-carousel--collapsed {
  height: $search-billboard-collapsed-height;

  .#{$search-prefix}-billboard__content {
    top: -10px;
  }
}
