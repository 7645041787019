@import '../../../styles/variables';
@import '../../../styles/common';
@import '../../../styles/andes_components';

@import '~cpg-nordic/src/styles/cpg.desktop';
@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';
@import '~@recommendations-fe/shops/src/styles/recommendations-fe-shops.desktop';
@import '~@ui-autoparts-web/compats/src/styles/desktop';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.desktop';
@import '~@seo-frontend-components/extended-menu/index';
@import '~@seo-frontend-components/dynamic-accordions-links/index';
@import '~@seo-frontend-components/card-blog-carousel/index';
@import '~@seo-frontend-components/card-blog-entry/index';
@import '~@seo-frontend-components/keyword-summary/index';
@import '~@seo-frontend-components/keyword-summary-mobile/index';

@import '../../../components-v2/icons/styles/desktop';
@import '../../../components/card/spotlight/styles/spotlight.desktop';
@import '../../../components-v2/sidebar/styles/sidebar.desktop';
@import '../../../components/sidebar/components/view-options/styles/view-options.desktop';
@import '../../../components-v2/sidebar/components/filter-sort/styles/filter-sort';
@import '../../../components-v2/sidebar/components/filter-official-store/styles/filter-official-store.desktop';
@import '../../../components-v2/sidebar/components/highlighted-filter/styles/highlighted-filter.desktop';
@import '../../../components/sidebar/components/highlighted-filter-vis/styles';
@import '../../../components/animated-switch/styles/animated-switch';
@import '../../../components/view-change/styles/view-change.desktop';
@import '../../../components-v2/sidebar/components/breadcrumb/styles/breadcrumb.desktop';
@import '../../../components-v2/sidebar/components/filters-colors/styles/filtersColors.desktop';
@import '../../../components-v2/sidebar/components/filters-tooltip/styles/desktop';
@import '../../../components/sidebar/components/filters/styles/filters.desktop';
@import '../../../components/filters/range-filters/styles/range-filters.desktop';
@import '../../../components-v2/sidebar/styles/search-result.desktop';
@import '../../../components/layout/styles/layout.desktop';
@import '../../../components/filters/applied/styles/applied-filters.desktop';
@import '../../../components-v2/modal/styles/desktop';
@import '../../../components-v2/money-picker/styles/desktop';
@import '../../../components/pagination/styles/pagination.desktop';
@import '../../../components-v2/sidebar/components/search-modal/styles/index.scss';
@import '../../../components-v2/bookmark/styles/bookmark';
@import 'app/components/top-keywords/styles/top-keywords.desktop';
@import '../../../components/reviews/styles/reviews.desktop';
@import '../../../components-v2/sidebar/styles/dropdown.desktop';
@import '../../../components/card/variations/styles/variations-picker';
@import '../../../components/card/variations/styles/variations-pill';
@import '../../../components/card/variations/styles/variations.desktop';
@import '../../../components/main-header/styles/main-header.desktop';
@import '../../../components/card/pds/styles/pds-options';
@import '../../../components/card/promise/styles/promise';
@import '../../../components/card/attributes/styles/attributes.desktop';
@import '../../../components/card/pds/styles/pds-card.desktop';
@import '../../../components/card/highlight/styles/highlight-label.desktop';
@import '../../../components/alert/styles/alert.desktop';
@import '../../../components/carousel/styles/carousel.desktop';
@import '../../../components-v2/intervention/billboard/styles/billboard-intervention.desktop';
@import '../../../components-v2/intervention/vehicle-body-selector/styles/index';
@import '../../../components-v2/header/styles/deal-header.desktop';
@import '../../../components-v2/header/styles/exhibitor-header.desktop';
@import '../../../components-v2/header/styles/official-store-header.desktop';
@import '../../../components/tooltip/styles/tooltip.desktop';
@import '../../../components/banner/styles/banner.desktop';
@import '../../../components/reputation-scale/styles/reputation-scale';
@import '../../../components/cross-site-modal/styles/cross-site-modal.desktop';
@import '../../../components/advertising/styles/advertising';
@import '../../../components/listing-disclaimer/styles/listing-disclaimer.desktop';
@import '../../../components/footer-disclaimer/styles/footer-disclaimer.desktop';
@import '../../../components/vehicle-body-selector/styles/vehicle-body-selector';
@import '../../../components/bottom-ads/styles/bottom-ads';
@import '../../../components/card/add-to-cart/styles/add-to-cart.desktop';
@import '../../../components/suggestions/styles/suggestions.desktop';
@import '../../../components/intervention/andes-message-intervention/styles/andes-message-intervention.desktop';
@import '../../../components/info-text-box/styles/info-text-box.desktop';
@import '../../../components/collapsible-card/styles/collapsible-card.desktop';
@import '../../../components/collapsible-card-list/collapsible-card-list';
@import '../../../components/seo-contents/styles/seo-contents.desktop';
@import '../../../components-v2/footer-components/styles/desktop';
@import '../../../components/full-snackbar/styles/full-snackbar.desktop';
@import '../../../components/full-snackbar/styles/full-popup.desktop';
@import '../../../components/full-snackbar/styles/full-shipping-info-skeleton.scss';
@import '../../../components/card/rebates/styles/rebates';
@import '../../../components/card/melicoin/styles/index';
@import '../../../components/card/promotions/styles/index';
@import '../../../components-v2/pricing-banner/styles/pricing-banner.desktop';
@import '../../../components/card/installments/styles/installments.desktop';
@import '../../../components/card/value-proposition/styles/value-proposition.desktop';
@import '../../../components/card/vertical-highlight/styles/vertical-highlight.desktop';
@import '../../../components/value-propositions-details/styles/value-propositions-details-modal.desktop';
@import '../../../components/boleto-discount-message/styles/boleto-discount-message.desktop';
@import '../../../components/card/price-details/styles/price-details.desktop';
@import '../../../components/card/price/styles/price';
@import '../../../components-v2/icons/svg-sprites/styles/index';
@import '../../../components/carousel-search/styles/carousel-search.desktop';
@import '../../../components/compats/styles/index';
@import '../../../components-v2/intervention/filter-intervention/styles/filter-intervention.desktop';
@import '../../../components/card/promise-message/styles/promise-message';
@import '../../../components/brand-ads/styles/brand-ads.desktop';
@import '../../../components/intervention/shopping-cart/styles/shopping-cart-intervention';
@import '../../../components/free-shipping-threshold-label/styles/free-shipping-threshold-label';
@import '../../../components-v2/intervention/meliplay/styles/desktop/index.scss';
@import '../../../components/intervention/meliplay/styles/desktop/index.scss';
@import '../../../components/pills-builder/styles/pill.desktop';
@import '../../../components/winner-alternatives/styles/winner-alternatives.desktop';
@import '../../../components/winner-alternatives/styles/composed-label-alternative.desktop';
@import '../../../components-v2/eshops/styles/eshops.desktop';
@import '../../../components/intervention/more-info-intervention/styles/more-info-intervention.desktop';
@import '../../../components/intervention/quick-access-intervention/styles/desktop';
@import '../../../components/seo-contents/styles/seo-contents.desktop';
@import '../../../components/seo-contents/styles/seo-extended-menu.desktop';
@import '../../../components/seo-smart-tabs/styles/seo-smart-tabs';
@import '../../../components-v2/intervention/seo-keyword-summary-intervention/styles/seo-keyword-summary-intervention.desktop';
@import '../../../components-v2/intervention/seo-info-image-text-box-intervention/styles/desktop';
@import '../../../components/segment/styles/segment.desktop';
@import '../../../components/segment-top-keywords/styles/segment-top-keywords.desktop.scss';
@import '../../../components/call-to-action-page/styles/call-to-action-page.desktop';

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 1;
}

.#{$search-prefix} {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;

  &-main {
    display: flex;
    margin-top: 32px;
    width: 100%;

    &--official-store {
      margin-top: 20px;
    }

    &--with-topkeywords {
      margin-top: 0;
    }
  }

  // sass-lint:disable bem-depth, class-name-format
  .ui-cpg__department__menu {
    z-index: $z-index-l;
  }
}

.#{$search-prefix}-top-keywords ~ .#{$search-prefix}-main {
  margin-top: 0;
}

.#{$search-prefix}-top-keywords,
.#{$search-prefix}-main {
  max-width: 1215px;
  padding-left: 10px;
  padding-right: $andes-spacing-28;
  width: 100%;
}

.#{$search-prefix}-result .#{$search-prefix}-item__highlight-label.#{$search-prefix}-item__shipping__highlight {
  font-size: $font-size-14;
  line-height: 16px;
  padding: 1px 4px 2px;
  margin: 0;
  margin-top: $andes-spacing-8;
  display: block;
}

.onboarding-cp-button {
  height: auto;
}

// TODO: piso color y tamaño del chevron del dropdown de andes hasta que salgan desde andes con el fix(14-10)
.andes-dropdown__trigger .andes-dropdown__standalone-arrow svg {
  margin: 2px 3px 0 0;
  width: 15px;
  height: 15px;
  fill: $search-dodger-blue;

  path {
    fill-opacity: 1;
  }
}

.andes-dropdown.andes-dropdown--standalone .andes-floating-menu .andes-dropdown__standalone-arrow path {
  fill: $search-dodger-blue;
}

.ui-compats__inputs {
  .andes-form-control__field:focus-visible {
    box-shadow: none;
  }
}

.andes-dropdown.andes-dropdown--standalone.andes-dropdown--open .andes-floating-menu .andes-dropdown__trigger {
  box-shadow: none;
}

.nav-area a.nav-cart span.nav-header-visually-hidden {
  display: none;
}
