// sass-lint:disable no-qualifying-elements, no-misspelled-properties
@import '../../../../../styles/variables';
@import '~@andes/switch/index';

.ui-search-filter-dl:first-child .#{$search-prefix}-filter-highlighted {
  margin-top: -3px;
}

.#{$search-prefix}-filter-highlighted-SHIPPING_ORIGIN_HIGHLIGHTED .ui-search-filter-highlighted__content {
  padding-top: $search-spacing-2;
  padding-bottom: $search-spacing-5;
  width: 155px;
}

.ui-search-sidebar .#{$search-prefix}-filter-highlighted {
  &__content-button {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.#{$search-prefix}-filter-highlighted-coin_highlighted {
  form > button > label {
    display: flex;
    background-color: $search-yellow-fff1cb;
    border-radius: $border-radius-2;
    justify-content: center;
    align-items: center;
    color: $search-brown-a47b2e;
    height: 20px;
    padding-left: $andes-spacing-4;
    padding-right: $andes-spacing-4;

    svg {
      margin-left: $andes-spacing-4;
    }
  }
}

.andes-tooltip--highlight.andes-card {
  width: 312px;
  padding-right: $search-spacing-32;
}

.#{$search-prefix}-filter-highlighted {
  $root: &;

  width: 242px;
  margin-top: -24px;
  cursor: pointer;

  &__container {
    background: $search-white-100;
    border: $search-spacing-1 solid $search-gray-500;
    border-radius: $border-radius-6;
    margin: $andes-spacing-4 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    gap: $andes-spacing-8;

    a {
      width: 100%;

      &::before {
        content: '';
        width: 100%;
        z-index: $z-index-xxl;
        position: absolute;
        height: 100%;
        left: 0;
      }
    }

    .andes-tooltip__trigger,
    & > svg.ui-search-icon {
      &--visible,
      & > div[class=''] {
        display: flex;
      }
    }

    .andes-tooltip__content {
      margin-right: 0;

      .andes-button {
        padding: $search-spacing-9 $search-spacing-12 $search-spacing-7;
        gap: $search-spacing-10;
        width: 107px;
        height: 32px;
        border-radius: $border-radius-5;

        .andes-button__content {
          font-size: $font-size-14;
        }
      }
    }

    @media (width <=1024px) {
      width: 95%;
    }
  }

  &__title {
    margin-top: $search-spacing-1;
    display: inline-block;
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    line-height: $line-height-15;
    white-space: nowrap;
    color: $search-gray-r080;
    cursor: pointer;
  }

  &__subtitle {
    margin-top: $search-spacing-5;
    font-size: $font-size-12;
    font-weight: normal;
    display: inline-block;
    color: $search-gray-r080;
    cursor: pointer;
  }

  .ui-search-icon {
    &--full {
      width: 45px;
      height: 15px;
      vertical-align: middle;
      margin-right: $search-spacing-2;
    }

    &--international-filter-cbt {
      margin-bottom: -5px;
    }
  }

  &__switch {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px;

    .andes-switch__input {
      display: flex;
      align-items: center;

      &::before {
        height: 18px;
        width: 34px;
      }

      &::after {
        top: $search-spacing-4;
        width: 16px;
        height: 16px;
      }

      &:checked::after {
        left: $andes-spacing-16;
      }
    }
  }

  &__label {
    margin-top: $search-spacing-4;
    display: inline-block;
    font-weight: normal;
    font-size: $font-size-12;
    color: $search-gray-450;
  }

  &__meli-plus {
    margin-top: $search-spacing-1;
    display: flex;
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    line-height: $line-height-15;
    white-space: nowrap;
    color: $search-gray-r080;
    align-items: center;
    gap: $search-spacing-5;
  }

  span:has(.#{$search-prefix}-icon--meliplus) {
    display: flex;
    align-items: center;
  }

  .#{$search-prefix}-icon--meliplus {
    margin-left: 5.6px;
  }

  .ui-search-item__highlight-label {
    font-size: $font-size-14;
  }

  &__tooltip {
    z-index: 11;
    position: absolute;
    right: 60px;
    bottom: $search-spacing-10;
  }

  &-WITH_VIRTUAL_TOUR_HIGHLIGHTED {
    #{$root}__title {
      display: flex;
      align-items: center;

      > svg {
        margin-right: $andes-spacing-8;
      }
    }
  }

  &-RENTAL_TYPE_HIGHLIGHTED .#{$search-prefix}-item__highlight-label--no-background {
    font-weight: $font-weight-semibold;
  }
}

.ui-search-filter-highlighted-new-buyer-shipping-cost-highlighted-free {
  .ui-search-filter-highlighted__title {
    color: $search-green-100;
  }
}

.#{$search-prefix}-filter-highlighted-RENTAL_TYPE_HIGHLIGHTED {
  .#{$search-prefix}-filter-highlighted__container {
    align-items: flex-start;

    span {
      &:first-child {
        vertical-align: middle;
      }
    }
  }
}
