@import '../../../styles/variables';

.#{$search-prefix}-footer-disclaimer {
  display: block;
  align-self: center;
  margin-bottom: $andes-spacing-16;

  &:last-child {
    margin-bottom: $andes-spacing-32;
  }

  .#{$search-prefix}-layout + &,
  .#{$search-prefix}-listing-disclaimer + & {
    margin-top: 30px;
  }

  &__text {
    font-size: $font-size-16;
    color: $search-gray-350;
    font-weight: $font-weight-regular;
    display: inline-block;
    line-height: 21px;
    text-align: center;

    &::before {
      content: '*';
      position: relative;
      top: 2px;
      right: 1px;
      font-size: 19px;
      margin-left: -7px;
    }
  }
}
