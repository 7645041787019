.ui-search-filter-highlighted-vis {
  &__content {
    line-height: 15px;

    .#{$search-prefix}-item__highlight-label {
      color: $search-gray-r080;
    }
  }

  &__switch-container {
    display: flex;
    align-items: center;

    //Cuando se habilite el switch grande -borrar este bloque-
    .andes-switch__input {
      display: flex;
      align-items: center;

      &::before {
        height: 18px;
        width: 34px;
      }

      &::after {
        top: 4px;
        width: 16px;
        height: 16px;
      }

      &:checked::after {
        left: 16px;
      }
    }
  }

  &__content-button {
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    cursor: pointer;
    min-height: 21.594px;
    width: -webkit-fill-available;
    flex-grow: 5;
    z-index: $z-index-xl;

    label {
      cursor: pointer;
    }
  }

  &__tooltip {
    z-index: 11;
  }

  &__container {
    background: $search-white-100;
    border: 1px solid $search-gray-500;
    border-radius: 6px;
    padding: 15px 12px 15px 16px;
    margin: 4px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    gap: 8px;

    .andes-tooltip__trigger,
    &>svg.ui-search-icon {

      &--visible,
      &>div[class=''] {
        display: flex;
      }
    }

    .andes-tooltip__content {
      margin-right: 0;

      .andes-button {
        padding: $search-spacing-9 $search-spacing-12 $search-spacing-7;
        gap: $search-spacing-10;
        width: 107px;
        height: 32px;
        border-radius: 5px;

        .andes-button__content {
          font-size: 14px;
        }
      }
    }

    @media (width <=1024px) {
      padding: 11px;
      width: 95%;
    }
  }

  &-modal {
    min-width: 855px;
    min-height: 577px;

    .andes-modal__content {
      height: 539px;

      iframe {
        border: none;
      }
    }
  }

  cursor: default;

  &__container-vertical {
    .ui-search-filter-highlighted-vis {
      &__label-subtitle-fade {
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        color: $andes-gray-550;
        margin-top: $andes-spacing-4;
        display: inline-block;
      }

      &__label-title-fade {
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 18px;
        white-space: normal;
        margin-top: $andes-spacing-4;
        display: inline-block;
      }
    }
  }

  &__icons-container {
    display: flex;
    align-items: center;
  }

  &__icon-label {
    background-color: #00a650;
    padding: 2px $andes-spacing-4;
    font-size: $font-size-14;
    font-style: normal;
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;
    color: $andes-white;
    margin-right: $andes-spacing-8;
    border-radius: 2px;
    line-height: 18px;
    width: 144px;

    .ui-search-icon {
      margin-right: $andes-spacing-4;

      &--melichoice-logo {
        position: relative;
        top: 2px;
      }
    }
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 16px;
  }

  &__switch-wrapper-vis {
    .andes-tooltip-data__arrow {
      margin-top: $andes-spacing-12;
    }
  }

  &__switch-icon {
    bottom: $search-spacing-9;
    padding: 0;
  }
}