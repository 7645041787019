@import '../../../styles/variables';
@import '../../../styles/mixins.desktop-small';
@import '~@andes/carousel-snapped/index';

// sass-lint:disable no-vendor-prefixes
// sass-lint:disable nesting-depth

.#{$search-prefix}-carousel {
  $self: &;

  align-self: center;

  &--official_stores {
    @include official-stores-carousel-breakpoint-m;
    @include official-stores-carousel-breakpoint-s;
    @include official-stores-carousel-arrows-breakpoint-m;
    @include official-stores-carousel-arrows-breakpoint-s;

    margin: $search-spacing-10 0 -2px;
    width: 900px;

    .carousel-container .prev-button {
      width: 32px;
      height: 32px;
      left: -35px;

      &::before {
        margin-top: -5px;
        margin-left: -3px;
        width: 12px;
      }

      &::after {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }
    }

    .next-button {
      width: 32px;
      height: 32px;
      right: -25px;

      &::before {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }

      &::after {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }
    }

    .carousel-container {
      .prev-button,
      .next-button {
        background: unset;
        box-shadow: none;
        top: 22%;

        &:not(.arrow-disabled) {
          display: block;
        }
      }
    }

    #{$self}__item {
      @include size(209px, 70px);

      background-color: $search-gray-100;
      border-radius: $border-radius-4;
      margin-right: 10px;

      &__image-container {
        @include size(65px, 64px);

        display: flex;
        justify-content: center;
        padding: 6px;
      }

      &__data {
        margin: 10px;
        margin-left: $andes-spacing-4;
      }

      &__image {
        @include square(55px);
      }

      &__data-name {
        color: $andes-gray-900;
        font-size: $font-size-14;
        font-weight: $font-weight-semibold;
        line-height: 1.375;
        white-space: nowrap;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__data-label {
        color: $andes-gray-550;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        margin-top: 1px;
      }

      #{$self}__item--container {
        width: 209px;
        height: 70px;
        display: flex;
        box-shadow: 0 1px 2px 0 $search-result-gallery-shadow-color;
        inset: 0;
        background-color: $andes-white;
        border-radius: $border-radius-4;
        align-items: center;
        margin-right: 12px;
        position: relative;

        &::after {
          box-shadow: 0 7px 16px 0 $andes-gray-100, 0 2px 4px 0 $andes-gray-100;
          content: '';
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.25s ease-in-out;
          z-index: $z-index-s;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }

        &:hover::after {
          opacity: 1;
        }
      }
    }

    .slick-list {
      padding-left: $andes-spacing-8;
    }

    .slick-track {
      display: flex;
      max-height: 90px;
    }
  }

  &--brands {
    @include brands-carousel-breakpoint-l;
    @include brands-carousel-breakpoint-m;
    @include brands-carousel-breakpoint-s;
    @include brands-carousel-arrows-breakpoint-l;
    @include brands-carousel-arrows-breakpoint-m;
    @include brands-carousel-arrows-breakpoint-s;

    height: 112px;
    width: 900px;
    margin: $search-spacing-8 0 $search-spacing-6 $andes-spacing-8;

    .carousel-container .prev-button {
      width: 32px;
      height: 32px;
      left: -37px;

      &::before {
        margin-top: -5px;
        margin-left: -3px;
        width: 12px;
      }

      &::after {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }
    }

    .next-button {
      width: 32px;
      height: 32px;
      right: -23px;

      &::before {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }

      &::after {
        margin-bottom: -5px;
        margin-left: -3px;
        width: 12px;
      }
    }

    .carousel-container {
      .prev-button,
      .next-button {
        background: unset;
        box-shadow: none;
        top: 33%;

        &:not(.arrow-disabled) {
          display: block;
        }
      }
    }

    .slick-slide {
      min-height: 104px;
      margin-top: -3px;
    }

    #{$self}__item {
      &__header {
        display: none;
      }

      &--container {
        @include square(104px);

        overflow: hidden;
        position: relative;
        margin-right: $andes-spacing-8;
      }

      &__image-container {
        align-items: center;
        background-color: $andes-white;
        display: flex;
        padding: $search-spacing-6;
        box-shadow: 0 1px 4px 0 $search-result-gallery-shadow-color;
        border-radius: $border-radius-4;
        width: 96px;
        height: 96px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &--view_more {
          border-radius: $border-radius-4;
          justify-content: center;
          left: calc(50% - 3px);

          .#{$search-prefix}-modal__link {
            z-index: 10;
            outline-offset: 11px;
          }
        }

        &::after {
          box-shadow: 0 7px 16px 0 $andes-gray-100, 0 2px 4px 0 $andes-gray-100;
          content: '';
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.25s ease-in-out;
          z-index: $z-index-s;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }

        &:hover::after {
          opacity: 1;
        }

        .ui-search-modal__link {
          @include square(100%);

          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }

      &__image {
        line-height: 86px;
        max-height: 86px;
        max-width: 86px;
      }
    }

    .slick-slide {
      &:not(.slick-active) {
        .#{$search-prefix}-carousel__item--container {
          box-shadow: none;
        }
      }
    }

    .slick-track {
      display: flex;
      max-height: 120px;
    }
  }

  &--cpg #{$self}__item-generic-card,
  &--cpg #{$self}__item-generic-main {
    @include size(310px, 120px);

    background-color: $andes-white;
    border-radius: $border-radius-4;
    margin-right: 10px;
  }

  &--cpg #{$self}__item-generic-card,
  &--cpg #{$self}__item-generic-main {
    display: inline-block;
  }

  &--cpg {
    @include cpg-carousel-breakpoint-l;
    @include cpg-carousel-breakpoint-m;
    @include cpg-carousel-breakpoint-s;

    width: 900px;
    height: 299px;

    .slick-slide {
      @include cpg-carousel-slides-breakpoint-l;
      @include cpg-carousel-slides-breakpoint-m;
      @include cpg-carousel-slides-breakpoint-s;

      height: 254px;
      flex-grow: 1;
      max-width: 164px;
      overflow: visible;
      z-index: 1;
      margin: 0 8px 50px;

      li {
        display: inline-block;
        height: 100%;
      }

      &:nth-child(2) {
        .ui-search-carousel__item-generic-card {
          box-shadow: unset;
        }
      }

      &:first-child {
        z-index: 2;
      }
    }

    .slick-list {
      max-height: 299px;
      overflow: hidden;
    }

    .slick-track {
      padding: 8px 0 32px;
    }

    .carousel-container {
      .prev-button {
        width: 36px;
        height: 36px;
        left: -6px;

        &::before {
          margin-top: -5px;
          margin-left: -6px;
          width: 12px;
        }

        &::after {
          margin-bottom: -5px;
          margin-left: -6px;
          width: 12px;
        }
      }

      .next-button {
        width: 36px;
        height: 36px;
        right: -6px;

        &::before {
          margin-bottom: -5px;
          margin-left: -6px;
          width: 12px;
        }

        &::after {
          margin-bottom: -5px;
          margin-left: -6px;
          width: 12px;
        }
      }

      .prev-button,
      .next-button {
        background: $andes-white;
        box-shadow: 0 2px 4px 0 $search-gray-150;
        top: 125px;

        &:not(.arrow-disabled) {
          display: block;
        }
      }
    }

    #{$self}__item-generic-main {
      @include cpg-carousel-main-card-breakpoint-l;
      @include cpg-carousel-main-card-breakpoint-m;
      @include cpg-carousel-main-card-breakpoint-s;

      width: 344px;
      height: 100%;
      transition: 0.3s;
      box-shadow: 0 1px 2px 0 $andes-gray-100;

      &:hover {
        box-shadow: 0 1px 18px 0 $andes-gray-100;
      }

      &__container {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 100%;
      }

      &__data {
        margin: 32px 8px 20px 24px;
        width: 180px;
      }

      &__title {
        font-size: $font-size-24;
        font-weight: 600;
        line-height: 1.16;
        letter-spacing: -0.2px;
        color: $search-gray-1500;
        text-wrap: balance;
      }

      &__subtitle {
        width: 180px;
        font-size: $font-size-16;
        font-weight: $font-weight-normal;
        line-height: 1.31;
        color: $search-gray-1500;
        margin-top: 16px;
      }

      &__image {
        margin: 36px 24px 0 0;
        height: 132px;
        width: 108px;
      }

      &__link {
        position: absolute;
        bottom: 15px;
        right: 0;
        padding-left: 20px;
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid $andes-gray-100;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.1px;
        color: $andes-blue-500;
        font-weight: 400;

        &::after {
          content: '';
          border: solid $andes-blue-500;
          border-width: 0 1.2px 1.2px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(-45deg);
          margin: 0 0 2px 4px;
        }
      }
    }

    #{$self}__item-generic-card {
      @include cpg-carousel-generic-card-breakpoint-l;
      @include cpg-carousel-generic-card-breakpoint-m;
      @include cpg-carousel-generic-card-breakpoint-s;

      max-width: 164px;
      height: 100%;
      transition: 0.3s;
      box-shadow: 0 1px 2px 0 $andes-gray-100;

      &:hover {
        box-shadow: 0 1px 18px 0 $andes-gray-100;
      }

      &__container {
        display: flex;
        flex-direction: column;
      }

      &__data {
        padding: 0 12px 16px;
        border-top: 1px solid $andes-gray-100;
        display: flex;
        position: relative;
        height: 90.5px;
      }

      &__title {
        @include size(140px, 28px);

        font-size: $font-size-12;
        font-weight: 300;
        line-height: 1.14;
        color: $search-gray-666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: absolute;
        bottom: $andes-spacing-12;
      }

      &__price {
        .#{$search-prefix}-price__part.price-tag.price-tag__disabled {
          margin-bottom: 0;
          position: absolute;
          top: 3px;
        }

        .#{$search-prefix}-price__discount {
          font-size: 12px;
          line-height: 1.17;
          color: $search-green-100;
        }

        .#{$search-prefix}-price__second-line {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          top: $andes-spacing-16;
        }

        .#{$search-prefix}-price__second-line .price-tag-fraction,
        .#{$search-prefix}-price__second-line .price-tag-symbol {
          font-size: $font-size-20;
          font-weight: $font-weight-regular;
        }

        .#{$search-prefix}-price__second-line .#{$search-prefix}-price__part {
          color: $andes-gray-900;
          margin-right: 5px;
          line-height: 1;
        }

        .#{$search-prefix}-price__second-line .andes-money-amount__fraction,
        .andes-money-amount__currency-symbol {
          font-size: $font-size-18;
        }

        .#{$search-prefix}-price__second-line .price-tag-symbol {
          margin-right: 6px;
        }

        .#{$search-prefix}-price__second-line .ui-search-price__discount {
          font-size: $font-size-12;
        }

        .#{$search-prefix}-price__second-line__label {
          display: flex;
        }

        .item__discount {
          font-size: $font-size-12;
        }

        .ui-search-price__original-value {
          margin-bottom: 5px;
          color: $search-gray-700;
          font-weight: $font-weight-regular;
          position: absolute;
          top: 3px;
        }

        .ui-search-price__original-value .andes-money-amount__fraction,
        .ui-search-price__original-value .andes-money-amount__currency-symbol {
          font-size: $font-size-12;
        }

        .ui-search-price__original-value .price-tag-fraction,
        .ui-search-price__original-value .ui-search-price__part,
        .ui-search-price__original-value .price-tag-symbol {
          font-size: $font-size-12;
        }

        .ui-search-price__original-value .price-tag-symbol {
          margin-right: 3px;
        }

        .item__discount {
          font-size: $font-size-10;
        }

        .price-tag-cents {
          font-weight: $font-weight-semibold;
        }
      }

      &__image {
        @include square(146px);
        @include cpg-carousel-generic-card-image-breakpoint-l;
        @include cpg-carousel-generic-card-image-breakpoint-m;
        @include cpg-carousel-generic-card-image-breakpoint-s;

        margin: 9px;
        object-fit: contain;
      }
    }

    #{$self}__item-generic__view-more {
      @include cpg-carousel-view-more-card-breakpoint-l;
      @include cpg-carousel-view-more-card-breakpoint-m;
      @include cpg-carousel-view-more-card-breakpoint-s;

      background-color: $andes-white;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-right: 10px;
      align-items: end;
      border-radius: $border-radius-4;
      width: 164px;
      height: 100%;
      transition: 0.3s;
      box-shadow: 0 1px 2px 0 $andes-gray-100;

      &:hover {
        box-shadow: 0 1px 18px 0 $search-result-gallery-shadow-color;
      }

      .cpg-icon__container {
        margin-top: 42px;
        border: 1px solid;
        height: 62px;
        width: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border-color: $search-gray-500;
      }

      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;

        .ui-search-icon--cpg-more g {
          fill: $andes-blue-500;
        }
      }

      &__title {
        font-size: $font-size-14;
        line-height: 1;
        font-weight: $font-weight-regular;
        text-align: center;
        color: $andes-gray-900;
        margin-top: 24px;
      }

      .andes-button {
        font-size: 14px;
        width: 132px;
        height: 36px;
        margin: 34px 16px 16px;
        padding: 0;

        .andes-button__content {
          line-height: 1;
        }
      }
    }
  }

  &__btn-collapse {
    width: 44px;
    height: 44px;
    display: block;
    border-width: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    z-index: 2;
    transform: rotate(90deg);
    bottom: $andes-spacing-12;
    right: $andes-spacing-12;
  }

  &__btn-collapse::before,
  &__btn-collapse::after {
    content: '';
    display: block;
    width: 20px;
    height: 3px;
    background: $andes-white;
    position: absolute;
    left: 50%;
    box-shadow: 0 1px 1px 0 $search-gray-620;
  }

  &__btn-collapse::before {
    transform: translate(-50%) rotate(-45deg);
    top: 50%;
    margin-top: -$andes-spacing-8;
  }

  &__btn-collapse::after {
    transform: translate(-50%) rotate(45deg);
    bottom: 50%;
    margin-bottom: -$andes-spacing-8;
  }

  &__btn-collapse {
    &:focus-visible {
      border: 2px solid $search-blue-600;
      border-radius: 6px;
    }
  }

  &--collapsed {
    .#{$search-prefix}-carousel__btn-collapse {
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}

.#{$search-prefix}-item-carousel__brand-tooltip {
  width: auto;
  font-size: $font-size-14;
  text-align: center;
  min-height: 32px;
  min-width: 87px;
  max-width: 150px;
  padding: 6px $andes-spacing-12;
  box-shadow: 0 8px 16px 0 $search-gray-160;

  .andes-tooltip-data__arrow::before {
    left: -4px;
    top: 1px;
  }

  .andes-tooltip__content {
    margin-right: 0;
  }
}

.mt-16 {
  margin-top: 16px;
}
