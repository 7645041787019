.#{$search-prefix}-segment {
  &__container {
    margin-bottom: $search-spacing-24;
  }

  &__header {
    display: flex;
    width: 100%;
    padding: $search-spacing-16 $search-spacing-24;
    justify-content: space-between;
    align-items: center;
    border-bottom: thin solid $search-gray-10;

    svg path {
      fill: $andes-gray-550;
    }
  }

  &__footer {
    width: 100%;
    padding: $search-spacing-16 $search-spacing-24;
  }

  &__title {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    scroll-margin: $search-spacing-24;
    color: $andes-gray-900;

    &:hover {
      color: $andes-gray-900;
    }
  }

  &__result {
    padding-left: $search-spacing-12;
    border-bottom: thin solid $search-gray-10;
  }
}